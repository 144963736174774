import "./App.css";
import Slider from "./Slider";
import cat from "./assets/kat.jpg";

function App() {
    return (
        <div className="App">
            <div className={"title"}>
                <span>Лови</span> приветик !
            </div>
            <div className={"content"}>
                <div className={"subtitle"}>
                    Приглашаем вас 7 марта, в 13:00 отведать что-то вкусное
                </div>
                <div className={"subtitle2"}>
                    А затем в 16:00 вас ждет сюрприз
                </div>
                <div className={"subtitle3"}>
                    Но мы не скажем куда вы пойдете ахаха)))
                </div>
                <div className={"subtitle4"}>
                    ВАЖНО! С собой нужно взять вещи которые можно чутка испачкать!
                </div>
                <div className={"subtitle4"}>
                    Кеды, сменную обувь (не тапки) чтобы было удобно
                </div>
                <div className={"subtitle4"}>
                    И сменную одежду что было удобно (не халат)
                </div>
            </div>
            <div className="container">
                <img src={cat} alt="Flying Kitty" className="kitty" id="kitty"/>
            </div>
            <Slider className={"slider1"}/>
            <Slider className={"slider2"}/>
            <Slider className={"slider3"}/>
            <Slider className={"slider4"}/>
        </div>
    );
}

export default App;
