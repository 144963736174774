import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {Autoplay} from "swiper/modules";

import girl1 from "./assets/girl1.jpg";
import girl2 from "./assets/girl2.jpg";
import girl3 from "./assets/girl3.jpg";
import girl4 from "./assets/girl4.jpg";
import girl5 from "./assets/girl5.jpg";
import girl6 from "./assets/girl6.jpg";
import girl7 from "./assets/girl7.jpg";
import girl8 from "./assets/girl8.jpg";
import girl9 from "./assets/girl9.jpg";
import girl10 from "./assets/girl10.jpg";
import girl11 from "./assets/girl11.jpg";

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export default function Slider({className}) {


    const girls = [girl1, girl2, girl3, girl4, girl5, girl6, girl7, girl8, girl9, girl10, girl11];
    const shuffledGirls = shuffleArray(girls);

    return (
        <div className={className}>
            <Swiper
                spaceBetween={40}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 1000,
                }}
                modules={[Autoplay]}

            >
                <div>

                    {shuffledGirls.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className={"slide low-quality"}>
                                <img src={item}/>
                            </div>
                        </SwiperSlide>
                    ))}

                </div>
            </Swiper>
        </div>
    );

}
